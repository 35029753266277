/**
 * Bing module.
 *
 * @return {Object}
 */
bb.bing = (function () {
  'use strict';

  var exports =  {};

  /**
   * Search for a query.
   *
   * @param {String}
   * @param {Number}
   * @param {Function}
   * @return {void}
   */
  exports.search = function (query, page, cb) {
    reqwest({
      url: '/wordpress/wp-admin/admin-ajax.php?callback=?',
      type: 'jsonp',
      data: {
        action: 'search_call',
        engine: 'bing',
        query: encodeURIComponent(query),
        page: page
      },
      success: cb,
      error: function () {
        cb([]);
      }
    });
  };

  return exports;
})();
