bb.contentExchangr = (function (){
  'use strict';

  var exports = {};

  var _xchange = function (e) {
    var val = $(this).attr('data-content');
    $('[data-content]').removeClass('active');
    $(this).addClass('active');
    $('[content-toggle]').html(val);
  };

  exports.init = function () {
    $('[data-content]').on('click', _xchange);
  };

  return exports;
})();
