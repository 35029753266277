/**
 * The validation module 'between' rule.
 *
 * @return {Object}
 */
bb.validator.rules.between = (function () {
  'use strict';

  var exports = {};

  /**
   * Check if data is valid.
   *
   * @param {String}
   * @param {Number}
   * @param {Number}
   * @return {Boolean}
   */
  exports.valid = function (data, min, max) {
    return bb.validator.valid('min', data, min) && bb.validator.valid('max', data, max);
  };

  return exports;
})();
