bb.tabs = (function (){
  'use strict';

  var exports = {};

  var _toggleTab = function (e) {
    $('[data-toggle]').parent().removeClass('active').removeClass('selected');
    $(this).parent().addClass('active');

    $('[data-tab]').removeClass('active');
    $('[data-tab="' + $(this).attr('href').replace('#', '') +'"]').addClass('active');

    if($(window).width() < 940) {
      _micromize();
      $(this).parent().addClass('selected');

      setTimeout(function () {
        $('html, body').animate({
          scrollTop: $('.tab__content.active').offset().top - 50
        }, 100);
      }, 200);
    }

    e.preventDefault();
  };

  var _micromize = function (e) {
    $('.tab__link').slideToggle(200).removeClass('active');
    return false;
  };

  exports.init = function (){
    $('[data-toggle]').on('click', _toggleTab);
    $('.tab__toggle').on('click', _micromize);

    if(window.location.hash !== '') {
      $('[data-toggle]').parent().removeClass('active');
      $('[data-toggle][href="' + window.location.hash + '"]').parent().addClass('active');
      $('[data-tab]').removeClass('active');
      $('[data-tab="' + $('[data-toggle][href="' + window.location.hash + '"]').attr('href').replace('#', '') +'"]').addClass('active');
    }
  };

  return exports;
})();
