/**
 * Form module.
 *
 * @return {Object}
 */
bb.form = (function () {
  'use strict';

  var exports =  {};

  /**
   * Submit the form.
   *
   * @param {Object}
   * @return {void}
   */
  exports.submit = function (data, cb) {
    reqwest({
      url: '/wordpress/wp-admin/admin-ajax.php',
      type: 'json',
      method: 'post',
      data: {
        action: 'save_call',
        data: data
      },
      success: cb,
      error: function () {
        cb([]);
      }
    });
  };

  return exports;
})();
