bb.stickyTab = (function () {
  'use strict';

  var exports = {};
  exports.last = 0;
  exports.query = null;

  var _fixed = function ($ele) {
    $ele.css({
      position: 'fixed',
      top: 0
    });
  };

  var _relative = function ($ele) {
    $ele.css({
      position: 'relative',
      top: ($ele.parent().height() - 140) - $ele.height()
    });
  };

  var _none = function ($ele) {
    $ele.attr('style', '');
  };

  var _scroll = function (e) {
    var $win = exports.win

    if($win.width() < 940)
      return false;

    var $ele = exports.ele;
    var $parent = $ele.parent();
    var offset = ($win.scrollTop() - $ele.offset().top) + exports.options.offset.height();

    var offsetBottoms = {
      element: $ele.offset().top - $ele.height(),
      parent: $parent.offset().top - 140
    };

    var upDir = ($ele.offset().top) - ($win.scrollTop());
    var parentOff = ($parent.offset().top) - ($win.scrollTop());
    var dir = exports.checkDir();

    exports.last = $win.scrollTop();

    if(offsetBottoms.element > offsetBottoms.parent) {
      _none($ele);
    }

    if((dir === 1) && (upDir < (parentOff + 100))) {
      if (exports.query !== 2){
        _none($ele);
        exports.query = 2;
      }
    }

    if(((offset > 0 && dir === 0) &&
      !(offsetBottoms.element > offsetBottoms.parent))
      || ((dir === 1) &&
      (upDir >= 100) &&
      (upDir > parentOff + 100)
      )) {

      if (exports.query !== 3) {
        _fixed($ele);
        exports.query = 3;
      }
    }
  };

  exports.checkDir = function () {
    return (exports.last > $(window).scrollTop()) ? 1 : 0;
  };

  exports.init = function (ele, options) {
    exports.options = options;
    exports.ele = $(ele);
    exports.win = $(window);
    exports.options.offset = $(exports.options.offset);
    $(document).bind('scroll', _scroll);
  };

  return exports;
})();
