/**
 * Moz module.
 *
 * @return {Object}
 */
bb.moz = (function () {
  'use strict';

  var exports =  {};

  /**
   * Get for a url.
   *
   * @param {String}
   * @param {Number}
   * @param {Function}
   * @return {void}
   */
  exports.get = function (url, cols, cb) {
    reqwest({
      url: '/wordpress/wp-admin/admin-ajax.php?callback=?',
      type: 'jsonp',
      data: {
        action: 'moz_call',
        url: encodeURIComponent(url),
        cols: cols
      },
      success: cb,
      error: function (err) {
        cb(false);
      }
    });
  };

  return exports;
})();
