bb.headhesive = (function () {
  'use strict';

  var exports = {};

  exports.init = function () {
    var options = {
      offset: 'main',

      // Custom classes
      classes: {

          // Cloned elem class
          clone: 'page-head__clone',

          // Stick class
          stick: 'page-head__clone--stick',

          // Unstick class
          unstick: 'page-head__clone--unstick'
      },

      // Throttle scroll event to fire every 250ms to improve performace
      throttle: 250,
  }
  var header = new Headhesive('.page-head', options);
  }

  return exports;
})();
