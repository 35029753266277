bb.equalyzr = (function () {
  'use strict';

  var exports = {};

  exports.init = function () {
    equalyzr.init({
        modules: {
          'foot-links': {
            minWidth: 940
          },
          'half-half': {
            minWidth: 940
          },
          'blog-hero': {
            minWidth: 940
          }
        }
    });
  };

  return exports;
})();
