/**
 * The validation module 'email' rule.
 *
 * @return {Object}
 */
bb.validator.rules.email = (function () {
  'use strict';

  var exports = {};
  var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  /**
   * Check if data is valid.
   *
   * @param {String}
   * @return {Boolean}
   */
  exports.valid = function (data) {
    return data.trim() === '' || regex.test(data);
  };

  return exports;
})();
