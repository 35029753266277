bb.redirectr = (function (){
  'use strict';

  var exports = {};

  var _search = function (e) {
    var $items = $('#items li');
    $('#search').keyup(function() {
      var val = '^(?=.*\\b' + $.trim($(this).val()).split(/\s+/).join('\\b)(?=.*\\b') + ').*$',
          reg = RegExp(val, 'i'),
          text;
      $items.show().filter(function() {
          text = $(this).text().replace(/\s+/g, ' ');
          return !reg.test(text);
      }).hide();
    });
  };

  var _toggleRedirector = function (e) {
    var attr = $('#search').attr('disabled');
    if(!attr || attr === undefined) {
      $('#search').prop('disabled', true);
      $('#search').attr('placeholder', "Select a website.");
    } else {
      $('#search').prop('disabled', false);
      $('#search').attr('placeholder', "Type to search.");
      $('#search').focus();
    }

    $('[redirector]').toggleClass('active');
    e.preventDefault();
  };

  var _redirectUser = function (e) {
    $('[redirector-label]').html($(this).html());
    $('[redirector]').removeClass('active');
    var $location = $(this).attr('redirector-location');
    setTimeout(function () {
        window.location = $location;
    }, 300);
  };

  exports.init = function () {
    $('[redirector-toggle]').on('click', _toggleRedirector);
    $('[redirector-options] > li').on('click', _redirectUser);
    $('#search').on('input', _search);
  };

  return exports;
})();
