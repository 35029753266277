bb.hero = (function(){
  'use strict';

  var exports =  {};

  var _event = function (e) {
    $('html').toggleClass('nav-active');
    e.preventDefault();
  };

  exports.init = function () {
    $('.page-nav__toggle, .page-head__overlay').on('click', _event);
  };

  return exports;
})();
