/**
 * A simple emitter module.
 *
 * @return {Object}
 */
bb.emitter = (function () {
  'use strict';

  var exports = {
    _listeners: {}
  };

  /**
   * Fires an event.
   *
   * @param  {String} event
   * @return {void}
   */
  exports.emit = function (event) {
    if (this._listeners[event] == null) { // jshint ignore:line
      return;
    }

    var args = Array.prototype.slice.call(arguments, 1);
    this._listeners[event].forEach(function (listener) {
      listener.apply(listener.__reactBoundContext ?
        listener.__reactBoundContext : (listener.__scope ?
          listener.__scope : null), args);
    });
  };

  /**
   * Listens to an event.
   *
   * @param  {String} event
   * @param  {Function} fn
   * @return {void}
   */
  exports.on = function (event, fn, scope) {
    if (this._listeners[event] == null) { // jshint ignore:line
      this._listeners[event] = [];
    }

    fn.__scope = scope;
    this._listeners[event].push(fn);
  };

  /**
   * Stops listening to an event.
   *
   * @param  {String} event
   * @param  {Function} fn
   * @return {void}
   */
  exports.off = function (event, fn) {
    if (this._listeners[event] == null) { // jshint ignore:line
      return;
    }

    this._listeners[event].forEach(function (listener, id) {
      if (listener !== fn) {
        return;
      }

      delete this._listeners[event][id];
    }.bind(this));
  };

  /**
   * Makes an object an emitter.
   *
   * @param {Object}
   * @return {Object}
   */
  exports.make = function (obj) {
    if (obj == undefined)
      obj = {};

    if (obj.prototype != null)
      obj = obj.prototype;

    obj._listeners = {};
    obj.emit = this.emit;
    obj.on = this.on;
    obj.off = this.off;

    return obj;
  };

  return exports;
})();
