/**
 * The validation module 'min' rule.
 *
 * @return {Object}
 */
bb.validator.rules.min = (function () {
  'use strict';

  var exports = {};
  var toStr   = Function.prototype.call.bind(Object.prototype.toString);
  var is      = function (type, thing) { return toStr(thing) === '[object ' + type + ']'; };

  /**
   * Check if data is valid.
   *
   * @param {String}
   * @param {Number}
   * @return {Boolean}
   */
  exports.valid = function (data, min) {
    if (!is('Number', data))
      data = data.length;

    return data === 0 || data >= min;
  };

  return exports;
})();
