/**
 * Smooth scroll module.
 *
 * @return {Object}
 */
bb.smoothScroll = (function () {
  'use strict';

  var exports =  {};

  /**
   * Scroll to the href of an element.
   */
  exports.to = function (id) {
    $('html, body').animate({
        scrollTop: $('#' + id).offset().top
    }, 500);
  };

  return exports;
})();
