/**
 * The validation module 'in' rule.
 *
 * @return {Object}
 */
bb.validator.rules.in = (function () {
  'use strict';

  var exports = {};

  /**
   * Check if data is valid.
   *
   * @param {String}
   * @param {Array}
   * @return {Boolean}
   */
  exports.valid = function (data, possibilities) {
    return data.trim() === '' || possibilities.indexOf(data) !== -1;
  };

  return exports;
})();
