bb.modal = (function () {
  'use strict';

  var exports = {};

  exports.init = function () {
    $('.modal-overlay, .modal-dismiss').on('click', _dismiss);
    $('.modal-show').on('click', _show);
    $(window).on("load", _animate);
  };

  var _animate = function (e) {
    $('#modal-show').addClass('animate-down');
    e.preventDefault();
  };


  var _dismiss = function (e) {
    $('html').removeClass('modal-active');
    $('body').removeClass('stop-scrolling');
    $('#video').attr('src', $('iframe').attr('src'));
    e.preventDefault();
  };

  var _show = function (e) {
    $('html').addClass('modal-active');
    $('body').addClass('stop-scrolling');
    e.preventDefault();
  };

  return exports;
})();
