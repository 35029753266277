/**
 * App
 */
var bb = (function () {
  'use strict';

  // @TODO: Move this to a module.
  var exports = {
    pages: {
      'form-step-one': {
        load: function () {
          document.body.removeChild(document.getElementById('form-container-remove'));

          React.render(React.createElement(Form.App), document.getElementById('form-container'));
        }
      }
    }
  };

  /**
   * Initialises all the modules
   */
  exports.init = function () {

    // Utils
    // exports.jsDetect.addClass();
    exports.svgDetect.addClass();
    exports.svgDetect.svgToPng();

    // Modules
    // exports.example.publicMethod();

    exports.headhesive.init();
    exports.hero.init();
    exports.tabs.init();
    exports.modal.init();

    exports.contentExchangr.init();
    exports.redirectr.init();
    // exports.equalyzr.init();
  };

  /**
   * Loads a page.
   *
   * @param {String} page
   */
  exports.loadPage = function (page) {
    if (bb.pages[page] == null)
      return;

    bb.pages[page].load();
  };

  return exports;
})();
