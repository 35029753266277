bb.maps = (function (){
  'use strict';

  var exports = {};

   exports.init = function () {
      var mapCanvas = document.getElementById('map-canvas');
      var loc = new google.maps.LatLng(40.7069772,-74.0095219)
      var mapOptions = {
        center: loc,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      var map = new google.maps.Map(mapCanvas, mapOptions);

      var marker = new google.maps.Marker({
          position: loc,
          map: map
      });
  }

  return exports;
})();
