/**
 * A simple validation module.
 *
 * @return {Object}
 */
bb.validator = (function () {
  'use strict';

  var exports = {
    rules: {}
  };

  /**
   * Check if data is valid against a rule.
   *
   * @param {String}
   * @return {Boolean}
   */
  exports.valid = function (rule) {
    if (exports.rules[rule] === undefined)
      return false;

    return exports.rules[rule].valid.apply(null,
      Array.prototype.slice.call(arguments, 1)
    );
  };

  return exports;
})();
