/**
 * The validation module 'required' rule.
 *
 * @return {Object}
 */
bb.validator.rules.required = (function () {
  'use strict';

  var exports = {};
  var toStr   = Function.prototype.call.bind(Object.prototype.toString);
  var is      = function (type, thing) { return toStr(thing) === '[object ' + type + ']'; };

  /**
   * Check if data is valid.
   *
   * @param {Object|String}
   * @return {Boolean}
   */
  exports.valid = function (data) {
    if (is('Object', data))
      return true;

    return data !== null && data.length > 0;
  };

  return exports;
})();
