bb.formy.validate = (function () {
  'use strict';

  var exports = {};

  var errorsMsgs = {
    'url' : 'Please Enter a valid Url',
    'email' : 'Please Enter a valid email',
    'require' : 'This field is required',
    'match' : 'Your field doesnt match its counterpart',
  };

  exports.match = function (arr) {
    var val;
    var $ele;
    var matches = true;

    for (var i = 0; i < arr.length; i++) {
      $ele = $(arr[i]);

      val = ((i === 0) ? $ele.val() : val);

      matches = (val === $ele.val()) ? matches : false;

      if(!(val === $ele.val()))
        exports.throwError(arr[i], 'match');
      else
        exports.removeError(arr[i]);
    };

    return matches;
  };

  exports.contains = function (obj) {
    var $eles = $(obj.target);
    var pass = true;
    var reg = obj.include;

    $eles.each(function (index) {
      var $ele = $(this);
      var val = $ele.val();

      if(reg.test(val)){
        exports.removeError(this);
      } else {
        pass = false;
        exports.throwError(this, 'contains', obj.message);
      }

    });

    return pass;
  }

  exports.throwError = function (ele, type, include) {

    var include = include || null;

    var $ele = $(ele);
    var $parent  = $ele.parent();

    $parent.addClass('input--invalid');

    if(type === 'contains')
      var errorMsg = 'Error: This field must contain ' + include;
    else
      var errorMsg = errorsMsgs[type];

    if($parent.children('p.brand--danger').length < 1)
      $('<p class="brand--warning milli">' + errorMsg + "</p>").appendTo($parent);

  };

  exports.removeError = function (ele) {
    var $ele = $(ele);

    $ele.parent().removeClass('input--invalid');
    $ele.parent().children('p').remove();
  }

  exports.url = function (data) {
    var regex =  new RegExp(/[-a-zA-Z0-9@:%_\+.~#?&\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)?/i);
    var pass = true;

    $(data).each(function(index) {
      var $ele = $(this);

      pass = (regex.test($ele.val())) ? pass : false;

      if(!regex.test($ele.val()))
        exports.throwError(this, 'url');
      else
        exports.removeError(this);
    });

    return pass;
  };

  exports.required = function (data) {
    var pass = true;

    $(data).each(function(index) {
      var $ele = $(this);
      var val = $ele.val();

      pass = (val.length > 0) ? pass : false;

      if(!val.length > 0)
        exports.throwError(this, 'require');
      else
        exports.removeError(this);
    });

    return pass;
  };

  exports.email = function (data) {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    var pass = true;
    var $ele;

    $(data).each(function(index) {
      $ele = $(this);

      pass = (regex.test($ele.val())) ? pass : false;

      if(!pass)
        exports.throwError(this, 'email');
      else
        exports.removeError(this);
    });

    return pass;
  };

  exports.process = function (obj) {
    var pass = true;

    /**
     * Loops Through required fields
     * @return {bool}
     */
    console.log('required');
    for (var i = 0; i < obj.required.length; i++) {
      pass = (exports.required(obj.required[i])) ? pass : false;
    };

    if (!pass) {
      return false;
    }
    /**
     * Loops Through Fields that should match
     * @return {bool}
     */
    console.log('match');
    for (var i = 0; i < obj.match.length; i++) {
      pass = (exports.match(obj.match[i])) ? pass : false;
    };

    if (!pass){
      return false;
    }

    /**
     * Loops Through Url Fields
     * @return {bool}
     */
    console.log('url');
    for (var i = 0; i < obj.url.length; i++) {
      pass = (exports.url(obj.url[i])) ? pass : false;
    };

    if (!pass){
      return false;
    }

    /**
     * Loops Through Url Fields
     * @return {bool}
     */
    console.log('contains');
    for (var i = 0; i < obj.container.length; i++) {
      pass = (exports.contains(obj.container[i])) ? pass : false;
    };

    if (!pass){
      return false;
    }
    /**
     * Loops Through Email fields
     * @return {bool}
     */
    console.log('email');
    for (var i = 0; i < obj.email.length; i++) {
      pass = (exports.email(obj.email[i])) ? pass : false;
    };

    if (!pass) {
      return false;
    }

    return pass;
  }

  return exports;
})();
