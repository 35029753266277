bb.formy = (function () {
  'use strict';

  var exports = {};

  var _addform = function (e) {
    var $elem = $(exports.data.containerElem).children(':first-child');
    var $e2 = $elem.clone(true).appendTo(exports.data.containerElem);

    $e2.find('input').val('');
    $e2.find('p.brand--danger').remove();
    $e2.removeClass('input--invalid');
    e.preventDefault();
  }

  var _removeform = function (e) {
    var $parent = $(this).parent().parent();
    var canRemove = ($parent.parent().children().length > 1) ? true : false;

    if (canRemove)
      $parent.remove();

    e.preventDefault();
  }

  var _validateForms = function (obj) {
    var $form = $(obj.form);
    $form.on('submit', function (e) {
      var pass = bb.formy.validate.process(obj);
      console.log(pass);
      if(!pass)
        e.preventDefault();
    });
  }

  exports.init = function (data) {
    exports.data = data;

    $(exports.data.addEle).on('click', _addform);
    $(exports.data.removeEle).on('click', _removeform);

    for (var key in data.validation) {
      _validateForms(data.validation[key]);
    };
  }

  return exports;
})();
